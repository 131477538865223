import React from 'react';
import { getContactContainerState } from '../../redux/selectors/spiderTxt';
import { setContactFilteredCategory } from '../../redux/slice/spidertxt/spiderTxt';
import { useSelector, useDispatch } from 'react-redux';
import { Conversation, ConversationList } from '@chatscope/chat-ui-kit-react';

import ContactListItem from './ContactListItem';
import { ContactSearchTextBox } from './ContactSearchTextBox';
import { Contact, ContactCategory, ContactType } from '../../types/spidertxt';
import ContactCategoryFilter from './ContactCategoryFilter';
import { Spin } from 'antd';

const ContactContainer: React.FC = () => {
  const dispatch = useDispatch();
  const {
    contacts: allContacts,
    conversations: allConversations,
    contactSearchText,
    contactFilteredCategory,
    initialLoadComplete
  } = useSelector(getContactContainerState);
  const contactSearchTextNotEmpty = contactSearchText.trim().length > 0;
  let allFilteredContacts: Contact[] = allContacts;

  if (contactSearchTextNotEmpty) {
    allFilteredContacts = allContacts.filter(contact =>
      contact.displayName.toLowerCase().includes(contactSearchText.toLowerCase())
    );
  }

  const contactIdsWithMessages = allConversations
    .filter(conversation => conversation.messages.length > 0)
    .map(conversation => conversation.contactId);

  const activeFilteredContacts = allFilteredContacts.filter(contact =>
    contactIdsWithMessages.includes(contact.id)
  );
  const groupFilteredContacts = allFilteredContacts.filter(
    contact => contact.contactType === ContactType.GROUP
  );
  const individualFilteredContacts = allFilteredContacts.filter(
    contact => contact.contactType === ContactType.INDIVIDUAL
  );

  let contactsToDisplay: Contact[] = allFilteredContacts;

  switch (contactFilteredCategory) {
    case ContactCategory.ACTIVE:
      contactsToDisplay = activeFilteredContacts;
      break;
    case ContactCategory.GROUP:
      contactsToDisplay = groupFilteredContacts;
      break;
    case ContactCategory.INDIVIDUAL:
      contactsToDisplay = individualFilteredContacts;
      break;
    case ContactCategory.ALL:
      contactsToDisplay = allFilteredContacts;
      break;
    default:
      contactsToDisplay = allFilteredContacts;
  }

  contactsToDisplay.sort((a, b) => {
    const conversationA = allConversations.find(conversation => conversation.contactId === a.id);
    const conversationB = allConversations.find(conversation => conversation.contactId === b.id);

    const convoAEmpty = conversationA == undefined || conversationA.messages.length == 0;
    const convoBEmpty = conversationB == undefined || conversationB.messages.length == 0;

    if (convoAEmpty && convoBEmpty) {
      return a.displayName.localeCompare(b.displayName);
    }
    if (convoAEmpty) {
      return 1;
    }
    if (convoBEmpty) {
      return -1;
    }

    const conversationALastMessageTimestamp = [...conversationA.messages].sort(
      (a, b) => b.timestamp - a.timestamp
    )[0]?.timestamp;
    const conversationBLastMessageTimestamp = [...conversationB.messages].sort(
      (a, b) => b.timestamp - a.timestamp
    )[0]?.timestamp;

    return conversationBLastMessageTimestamp - conversationALastMessageTimestamp;
  });

  const onCategorySelect = (category: ContactCategory) => {
    console.log(`Dispatching setContactFilteredCategory ${category}`);
    dispatch(setContactFilteredCategory({ contactFilteredCategory: category }));
  };

  const getCategoryPlural: (category: ContactCategory) => string = category => {
    switch (category) {
      case ContactCategory.ACTIVE:
        return 'threads';
      case ContactCategory.GROUP:
        return 'groups';
      case ContactCategory.INDIVIDUAL:
        return 'individuals';
      case ContactCategory.ALL:
        return 'contacts';
    }
  };

  return (
    <>
      <ContactSearchTextBox />
      <ContactCategoryFilter
        allCount={contactSearchTextNotEmpty ? allFilteredContacts.length : undefined}
        activeCount={contactSearchTextNotEmpty ? activeFilteredContacts.length : undefined}
        individualCount={contactSearchTextNotEmpty ? individualFilteredContacts.length : undefined}
        groupCount={contactSearchTextNotEmpty ? groupFilteredContacts.length : undefined}
        contactFilteredCategory={contactFilteredCategory}
        onCategorySelect={onCategorySelect}
      />
      {!initialLoadComplete && (
        <div className="no-contacts">
          <Spin />
        </div>
      )}
      {initialLoadComplete && allContacts.length === 0 && (
        <div className="no-contacts">You have no contacts</div>
      )}
      {allContacts.length != 0 && contactsToDisplay.length == 0 && (
        <div className="no-contacts">No {getCategoryPlural(contactFilteredCategory)} found</div>
      )}
      <ConversationList>
        {contactsToDisplay.map(contact => (
          <ContactListItem as={Conversation} key={contact.id} contact={contact} />
        ))}
      </ConversationList>
    </>
  );
};

export default ContactContainer;
