import React, { useEffect } from 'react';

import './styles/main.scss';
import {
  MainContainer,
  MessageList,
  MessageInput,
  Sidebar,
  ConversationHeader as CSConversationHeader,
  ChatContainer
} from '@chatscope/chat-ui-kit-react';
import ContactContainer from './ContactContainer';
import { MessageComposer } from './MessageComposer';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveContactId,
  getContactFilteredCategory,
  getContactFilterText
} from '../../redux/selectors/spiderTxt';
import ConversationHeader from './ConversationHeader';
import ConversationView from './ConversationView';
import { BlankConversation } from './BlankConversation';
import {
  resetFilteredTextAndCategory,
  setActiveContactId
} from '../../redux/slice/spidertxt/spiderTxt';
import { ContactCategory } from '../../types/spidertxt';

interface KeyPressEvent {
  keyCode: number;
}
const ESC_KEY_CODE = 27;

const SpiderTxtPage = () => {
  if (window.env.ENV !== 'local' && window.env.ENV !== 'development') {
    return <>New SpiderTxt only visible on local or development</>;
  }
  const dispatch = useDispatch();
  const activeConversationId = useSelector(getActiveContactId);
  const contactCategory = useSelector(getContactFilteredCategory);
  const contactSearchText = useSelector(getContactFilterText);

  // Allow using the Escape key to close the active conversation
  useEffect(() => {
    function handleKeyDown(e: KeyPressEvent) {
      const pressedKey = e.keyCode;
      if (pressedKey === ESC_KEY_CODE) {
        if (contactSearchText.trim() != '' || contactCategory != ContactCategory.ALL) {
          dispatch(resetFilteredTextAndCategory());
        } else {
          dispatch(setActiveContactId(undefined!));
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [contactCategory, contactSearchText]);

  return (
    <MainContainer>
      <Sidebar
        loading={false} // set to true when loading contacts intialy
        position="left"
      >
        <ContactContainer />
      </Sidebar>

      {!activeConversationId && <BlankConversation />}
      {activeConversationId && (
        <ChatContainer>
          <ConversationHeader as={CSConversationHeader} />
          <ConversationView as={MessageList} />
          <MessageComposer as={MessageInput} />
        </ChatContainer>
      )}
    </MainContainer>
  );
};

export default SpiderTxtPage;
