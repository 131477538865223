// todo move these types elsewhere

export enum MessageType {
  SYSTEM = 'SYSTEM',
  RECEIVED = 'RECEIVED',
  SENT = 'SENT'
}

export enum MessageStatus {
  SENDING = 'SENDING',
  DELIVERED = 'DELIVERED'
}

export interface MessageToSend {
  body: string;
  /** Is the groupId if the recipient is a group */
  contactId: string;
}

export interface Message extends MessageToSend {
  id: string;
  /** senderId can be null for system messages */
  senderId: string | undefined;
  timestamp: number;
  type: MessageType;
  status: MessageStatus;
}

/** Is render-ready because it has the sender display name */
export interface UIMessage extends Message {
  senderDisplayName: string;
}

export interface Conversation {
  readonly contactId: string;
  readonly messages: Message[];
  readonly unreadMessageCount: number;
}
/** Render ready because the message is of UIMessage type */
export interface UIConversation extends Omit<Conversation, 'messages'> {
  readonly messages: UIMessage[];
  readonly isAllMessagesLoaded: boolean;
}

export enum ContactType {
  INDIVIDUAL = 'INDIVIDUAL',
  GROUP = 'GROUP'
}

export interface BaseContact {
  id: string;
  displayName: string;
}

export interface UserContact extends BaseContact {
  contactType: ContactType.INDIVIDUAL;
  avatarUrl: string | undefined;
  organisations: string[];
}

export interface GroupContact extends BaseContact {
  contactType: ContactType.GROUP;
  memberIds: string[];
  organisationId: string;
  organisationName: string;
}

export type Contact = UserContact | GroupContact;

export enum ContactCategory {
  ACTIVE = 'ACTIVE',
  ALL = 'ALL',
  GROUP = 'GROUP',
  INDIVIDUAL = 'INDIVIDUAL'
}

export interface SpiderTxtState {
  runtime: {
    activeContactId: string | undefined;
    contactFilterText: string;
    contactFilteredCategory: ContactCategory;
    /** contactId -> string | undefined */
    draftMessages: { [key: string]: string };
    initialLoadComplete: boolean;
  };
  unreadMessageCount: number;
  readMessageMap: { [key: string]: string };
  /** contactId -> Contact */
  contacts: { [key: string]: Contact };
  /** contactId -> Conversation */
  conversations: { [key: string]: UIConversation };
}
