import React from 'react';
import { Search } from '@chatscope/chat-ui-kit-react';
import { setContactFilterText } from '../../redux/slice/spidertxt/spiderTxt';
import { useDispatch, useSelector } from 'react-redux';
import { getContactFilterText } from '../../redux/selectors/spiderTxt';

export const ContactSearchTextBox = () => {
  const dispatch = useDispatch();
  const search = (text: string) => {
    dispatch(setContactFilterText({ contactFilterText: text }));
  };

  const contactSearchText = useSelector(getContactFilterText);

  return (
    <Search
      onChange={e => {
        search(e);
      }}
      onClearClick={() => {
        search('');
      }}
      placeholder="Search"
      value={contactSearchText}
    />
  );
};
