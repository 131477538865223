import { Amplify } from '@spidertracks/common';
import { Contact, ContactType } from '../../../../../../types/spidertxt';
import { ServiceAbstract } from '../../../ServiceAbstract';

interface UserOrganisationMembership {
  organisationId: string;
  organisationName: string;
  role: string;
}
interface UserFromAPI {
  kind: 'user';
  id: string;
  spiderTxtId: number;
  displayName: string;
  avatarUrl?: string;
  organisationMembership: UserOrganisationMembership[];
  //email: string;
}

interface GroupFromAPI {
  kind: 'group';
  id: string;
  spiderTxtId: number;
  displayName: string;
  organisationName: string;
  organisationId: string;
  memberIds: string[];
}

export type ContactFromAPI = UserFromAPI | GroupFromAPI;

export interface ContactsAPIResponse {
  contacts: ContactFromAPI[];
}

export class ContactService extends ServiceAbstract {
  private transformToDomainType(contact: ContactFromAPI): Contact {
    if (contact.kind === 'user') {
      return {
        id: contact.id,
        organisations: contact.organisationMembership.map(
          membership => membership.organisationName
        ),
        displayName: contact.displayName,
        contactType: ContactType.INDIVIDUAL,
        avatarUrl: undefined // TODO fix when backend sends correct images
      };
    } else {
      return {
        id: contact.id,
        displayName: contact.displayName,
        contactType: ContactType.GROUP,
        organisationId: contact.organisationId,
        organisationName: contact.organisationName,
        memberIds: contact.memberIds
      };
    }
  }

  public async getContacts(): Promise<Contact[]> {
    try {
      const { contacts } = await Amplify.API.get(
        'api.spidertracks.io-authenticated',
        `v2/v2/contacts`
      );
      return contacts.map((contact: ContactFromAPI) => this.transformToDomainType(contact));
    } catch (e) {
      console.log(e);
      return [];
    }
  }
}
