import { MultiSelectForm, GroupSelectionOption, collapseGroups } from '@spidertracks/components';
import { Form, Row } from 'antd';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FormComponentProps } from 'antd/lib/form';
import React, { FC, useEffect } from 'react';

export interface User {
  id: string;
  name: string;
  isAdmin: boolean;
}

interface UserSelectorProps extends FormComponentProps {
  initialValue: CheckboxValueType[];
  isEditForm: boolean;
  showAdminChoice?: boolean;
  showAllChoice?: boolean;
  users: User[];
  userOptionTransformer: (u: User) => CheckboxOptionType;
  required?: boolean;
}

export const UserSelector: FC<UserSelectorProps> = ({
  form,
  initialValue,
  users,
  userOptionTransformer,
  showAdminChoice,
  showAllChoice,
  required=true
}) => {
  const onReset = () => form.setFieldsValue({ users: initialValue });

  useEffect(() => {
    form.resetFields();
  }, [initialValue.length]);

  const options = users.map(userOptionTransformer);

  const groups: GroupSelectionOption[] = [];
  const userArray: [string, User][] = users.map(value => {
    return [value.id, value];
  });
  const userById = new Map<string, User>([...userArray]);
  if (showAllChoice) {
    groups.push({
      label: 'All users',
      id: '*',
      onSelectFilter: (value: CheckboxValueType, index, array) => true,
      isSelectAll: true
    });
  }
  if (showAdminChoice) {
    groups.push({
      label: 'All admin',
      id: 'ADMINS',
      onSelectFilter: (value: CheckboxValueType, index, array) =>
        userById.get(value as string)?.isAdmin || false,
    });
  }
  const groupMapping = new Map<string, string>(
    groups.map((value: GroupSelectionOption) => [value.id, value.label])
  );

  return (
    <Form.Item label={'Users'} style={{ textAlign: 'left' }} className="mt-3">
      <Row>
        {form.getFieldDecorator('users', {
          initialValue,
          rules: [
            {
              required: required,
              message: 'Please select user'
            }
          ]
        })(
          <MultiSelectForm
            closedLabel={collapseGroups(
              groupMapping,
              'user',
              'users',
              required ? 'Select users' : 'Select users (optional)'
            )(form.getFieldValue('users').map((u: string) => userById.get(u)?.name || u))}
            fieldName="users"
            form={form}
            options={options}
            onReset={onReset}
            showSearch
            shifted={true}
            groups={groups}
          />
        )}
      </Row>
    </Form.Item>
  );
};

export default UserSelector;
