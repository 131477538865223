import React from 'react';
import { InputToolbox, MessageInput, SendButton } from '@chatscope/chat-ui-kit-react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveContactId, getDraftMessages } from '../../redux/selectors/spiderTxt';
import { setDraftMessage } from '../../redux/slice/spidertxt/spiderTxt';
import { markMessagesAsRead, sendMessage } from '../../redux/slice/spidertxt/thunk';
import TextArea from 'antd/lib/input/TextArea';

const MAX_MESSAGE_LENGTH = 200;

interface MessageComposerProps {
  as: string | typeof MessageInput;
}

// TODO move all styles to style files, this is mostly for fast editing
export const MessageComposer: React.FC<MessageComposerProps> = () => {
  const activeConversationId = useSelector(getActiveContactId)!;
  const activeDraftMessage = useSelector(getDraftMessages)[activeConversationId] ?? '';
  const dispatch = useDispatch();

  const [isSendInProgress, setIsSendInProgress] = React.useState(false);

  if (activeConversationId == undefined) {
    return <></>;
  }

  const remainingCharacterCount = MAX_MESSAGE_LENGTH - activeDraftMessage.length;
  const sendDisabled =
    activeDraftMessage.trim().length == 0 || remainingCharacterCount < 0 || isSendInProgress;

  const triggerSendMessage = () => {
    setIsSendInProgress(true);
    dispatch(
      sendMessage(activeConversationId, activeDraftMessage, () => {
        setIsSendInProgress(false);
      })
    );
  };

  return (
    <div className="cs-message-composer">
      <InputToolbox>
        <TextArea
          rows={2}
          spellCheck={true}
          onChange={e => {
            dispatch(
              setDraftMessage({
                contactId: activeConversationId,
                body: e.target.value
              })
            );
            dispatch(markMessagesAsRead(activeConversationId));
          }}
          value={activeDraftMessage}
          autoFocus={true}
          onFocus={e => {
            dispatch(markMessagesAsRead(activeConversationId));
          }}
          placeholder="Type message here"
          onKeyDown={e => {
            if (e.key === 'Enter' && e.ctrlKey && !sendDisabled) {
              triggerSendMessage();
            }
          }}
        />
        <span className={remainingCharacterCount < 0 ? 'counter text-danger' : 'counter'}>
          {remainingCharacterCount}
        </span>
        <SendButton
          border
          disabled={sendDisabled}
          onClick={() => {
            triggerSendMessage();
          }}
        />
      </InputToolbox>
    </div>
  );
};
