import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Tooltip, Button, Input, Checkbox, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { CheckboxOptionType } from 'antd/lib/checkbox';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import { AircraftSelector, ConfirmationButtons, UserSelector } from '../../../common/form';
import {
  OrganisationGroupMember,
  OrganisationMember
} from '../../../../common/api/spidertracks-sdk/private/services/UserService';
import { checkboxUserOptionTransformer } from '../../notifications/NotificationForm';
import { saveFormFieldChanges } from './saveOrganisationGroup';
import { showRemoveOrganisationGroupModal } from './RemoveOrganisationGroupModal';

export interface OrganisationGroupFormData {
  groupId: string;
  groupName: string;
  groupMembers: OrganisationGroupMember[];
  organisationAircraft: CheckboxOptionType[];
  groupSelectedAircraft: string[] | [];
  notificationsAvailable: boolean;
  spiderTxtAvailable: boolean;
  status: boolean;
}

export interface OrganisationGroupFormValues {
  groupName: string;
  users: string[];
  aircraft: string[];
  groupVisibility: string[]; // contains "spidertxt" and/or "notifications"
  status: string;
}

interface ComponentProps extends FormComponentProps {
  initialData: OrganisationGroupFormData | null;
  organisationMembers: OrganisationMember[];
  isAircraftVisibilityGroupsAdminEnabled: boolean;
  isNotificationsGroupsAdminEnabled: boolean;
  removeOrganisationGroup: (groupId: string) => void;
  onClose: () => void;
  onSave: (success: boolean, actionType: string) => void;
  form: WrappedFormUtils;
}

export const OrganisationGroupForm: React.FC<ComponentProps> = ({
  initialData,
  organisationMembers,
  isAircraftVisibilityGroupsAdminEnabled,
  isNotificationsGroupsAdminEnabled,
  removeOrganisationGroup,
  onClose,
  onSave,
  form
}) => {
  const formRef = useRef(form);
  const [isSaving, setIsSaving] = useState(false);

  const orgUserList = organisationMembers?.map(member => ({
    id: member.id,
    name: `${member.firstName} ${member.lastName}`,
    isAdmin: member.role === 'ADMIN',
    selected: initialData?.groupMembers.some(groupMember => groupMember.id === member.id)
  }));
  orgUserList.sort((user1, user2) => {
    if (user1.selected === undefined && user2.selected === undefined) {
      return user1.name.localeCompare(user2.name);
    }
    const selectedCompare = Number(user2.selected) - Number(user1.selected);
    if (selectedCompare !== 0) {
      return selectedCompare;
    }
    return user1.name.localeCompare(user2.name);
  });

  useEffect(() => {
    formRef.current = form;
  }, [form]);

  useEffect(() => {
    if (!initialData) {
      return;
    }

    const getGroupVisibility = (initialData: OrganisationGroupFormData) => {
      const groupVisibility: string[] = [];
      if (initialData.spiderTxtAvailable) {
        groupVisibility.push('spidertxt');
      }
      if (initialData.notificationsAvailable) {
        groupVisibility.push('notifications');
      }
      return groupVisibility;
    };

    formRef.current.setFields({
      groupName: { value: initialData.groupName },
      users: { value: initialData.groupMembers.map(member => member.id) },
      aircraft: {
        value: initialData.groupSelectedAircraft
      },
      status: { value: initialData.status ? 'Active' : 'Inactive' },
      groupVisibility: { value: getGroupVisibility(initialData) }
    });
  }, [initialData]);

  const aircraftVisibilityLabel = (
    <span>
      Aircraft Visibility
      <Tooltip
        placement="right"
        title={
          <span>
            Visibility allows you to select which group within your Organisation can view tracks
            from your aircraft. It allows the members in that group to see all aircraft by selecting
            the all aircraft option in the drop down selection.
          </span>
        }
      >
        <ExclamationCircleFilled className="ml-2" style={{ color: 'rgba(0,0,0,0.25)' }} />
      </Tooltip>
    </span>
  );

  const saveOrganisationGroup = async (formFieldValues: OrganisationGroupFormValues) => {
    setIsSaving(true);
    let success = true;
    const actionType = initialData ? 'update' : 'create';
    if (formFieldValues) {
      try {
        await saveFormFieldChanges(
          initialData,
          formFieldValues,
          organisationMembers[0].organisationId
        );
      } catch (e) {
        success = false;
      }
    }

    setIsSaving(false);
    onSave(success, actionType);
  };

  const formSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    formRef.current.validateFields((err, values) => {
      if (err) {
        return;
      }
      saveOrganisationGroup(values);
    });
  };

  return (
    <div>
      <Form onSubmit={formSubmit} layout="vertical">
        <Form.Item label="Group Name">
          {formRef.current.getFieldDecorator('groupName', {
            validateFirst: true,
            rules: [{ required: true, message: 'Group name is required.' }]
          })(<Input placeholder={initialData?.groupName} />)}
        </Form.Item>

        <UserSelector
          form={form}
          initialValue={[]}
          isEditForm={false}
          users={orgUserList}
          userOptionTransformer={checkboxUserOptionTransformer(false)}
          showAllChoice={false}
          showAdminChoice={false}
          required={false}
        />

        {isAircraftVisibilityGroupsAdminEnabled && (
          <AircraftSelector
            form={formRef.current}
            initialValue={initialData ? initialData.groupSelectedAircraft : []}
            isEditForm={false}
            options={initialData ? initialData.organisationAircraft : []}
            showAllChoice={false}
            showFixedWingChoice={false}
            showRotaryChoice={false}
            label={aircraftVisibilityLabel}
            allowNoneSelection={true}
          />
        )}

        <Form.Item label="Group Visibility">
          <Row style={{ paddingBottom: '8px' }}>
            {formRef.current.getFieldDecorator('groupVisibility', {
              initialValue: ['spidertxt', 'notifications']
            })(
              <Checkbox.Group>
                <Row style={{ paddingBottom: '8px' }}>
                  <Checkbox value="spidertxt">Enable Spidertxt group</Checkbox>
                </Row>
                {isNotificationsGroupsAdminEnabled && (
                  <Row style={{ paddingBottom: '8px' }}>
                    <Checkbox value="notifications">Show in Notifications setup</Checkbox>
                  </Row>
                )}
              </Checkbox.Group>
            )}
          </Row>
        </Form.Item>

        <Form.Item label="Status">
          {formRef.current.getFieldDecorator('status', {
            initialValue: initialData?.status === false ? 'Inactive' : 'Active',
            rules: [{ required: true, message: 'Please select the status of the group.' }]
          })(
            <Select>
              <Select.Option value="Active">Active</Select.Option>
              <Select.Option value="Inactive">Inactive</Select.Option>
            </Select>
          )}
        </Form.Item>

        <div
          style={{
            display: 'flex',
            justifyContent: 'right'
          }}
        >
          {initialData && (
            <Button
              type="danger"
              size="large"
              style={{ width: '85px', margin: '16px 8px 0 0' }}
              onClick={() => {
                if (initialData) {
                  showRemoveOrganisationGroupModal({
                    groupId: initialData.groupId,
                    setIsSaving: setIsSaving,
                    removeOrganisationGroup: removeOrganisationGroup
                  });
                }
              }}
            >
              Remove
            </Button>
          )}

          <ConfirmationButtons onClose={onClose} isSaving={isSaving} />
        </div>
      </Form>
    </div>
  );
};

OrganisationGroupForm.propTypes = {
  initialData: PropTypes.any,
  organisationMembers: PropTypes.array.isRequired,
  removeOrganisationGroup: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  form: PropTypes.any.isRequired
};
