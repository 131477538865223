import { FullState } from '../../store';
import { createSelector } from 'reselect';
import {
  Contact,
  ContactCategory,
  Conversation,
  SpiderTxtState,
  UIConversation
} from '../../types/spidertxt';

export const getSpiderTxt = (state: FullState): SpiderTxtState => state.spiderTxt;

export const getActiveContactId = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): string | undefined => spiderTxt.runtime.activeContactId
);

export const getUnreadMessageCount = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): number => spiderTxt.unreadMessageCount
);

export const getContacts = createSelector([getSpiderTxt], (spiderTxt: SpiderTxtState): Contact[] =>
  Object.values(spiderTxt.contacts)
);

export interface ContactContainerState {
  contacts: Contact[];
  conversations: Conversation[];
  contactSearchText: string;
  contactFilteredCategory: ContactCategory;
  initialLoadComplete: boolean;
}
export const getContactContainerState = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState) => {
    return {
      initialLoadComplete: spiderTxt.runtime.initialLoadComplete,
      contacts: Object.values(spiderTxt.contacts),
      conversations: Object.values(spiderTxt.conversations),
      contactSearchText: spiderTxt.runtime.contactFilterText,
      contactFilteredCategory: spiderTxt.runtime.contactFilteredCategory
    };
  }
);

export const getConversations = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): UIConversation[] => Object.values(spiderTxt.conversations)
);

export const getConversationMap = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): { [key: string]: UIConversation } => spiderTxt.conversations
);

export const getConversationById = createSelector(
  [getSpiderTxt, (_: FullState, id: string) => id],
  (state, id) => {
    const convo = state.conversations[id];
    if (convo != undefined) {
      return convo;
    }
    return {
      contactId: id,
      messages: [],
      unreadMessageCount: 0
    };
  }
);

export const getContactFilterText = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): string => spiderTxt.runtime.contactFilterText
);

export const getReadMessageMap = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState) => spiderTxt.readMessageMap
);

export const getContactFilteredCategory = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState): ContactCategory => spiderTxt.runtime.contactFilteredCategory
);

export const getDraftMessages = createSelector(
  [getSpiderTxt],
  (spiderTxt: SpiderTxtState) => spiderTxt.runtime.draftMessages
);
