import { createSlice } from '@reduxjs/toolkit';
import {
  SpiderTxtState,
  Contact,
  ContactCategory,
  UIConversation,
  UIMessage
} from '../../../types/spidertxt';

const initialState: SpiderTxtState = {
  runtime: {
    activeContactId: undefined,
    contactFilterText: '',
    contactFilteredCategory: ContactCategory.ALL,
    draftMessages: {},
    initialLoadComplete: false
  },
  unreadMessageCount: 0,
  readMessageMap: {},
  contacts: {},
  conversations: {}
};

export const spiderTxtSlice = createSlice({
  name: 'spiderTxt',
  initialState,
  reducers: {
    setActiveContactId: (state, action: { payload: string; type: string }) => {
      state.runtime.activeContactId = action.payload;
    },
    setDraftMessage: (
      state,
      action: { payload: { contactId: string; body: string }; type: string }
    ) => {
      state.runtime.draftMessages[action.payload.contactId] = action.payload.body;
    },
    setContactFilterText: (state, { payload }) => {
      state.runtime.contactFilterText = payload.contactFilterText;
    },
    setContactFilteredCategory: (state, { payload }) => {
      state.runtime.contactFilteredCategory = payload.contactFilteredCategory;
    },
    resetFilteredTextAndCategory: state => {
      state.runtime.contactFilteredCategory = ContactCategory.ALL;
      state.runtime.contactFilterText = '';
    },
    reset: state => {
      state.runtime.activeContactId = undefined;
      state.contacts = {};
      state.runtime.contactFilterText = '';
      state.unreadMessageCount = 0;
    },
    setContactsAndConversations: (
      state,
      action: {
        payload: {
          contacts: { [key: string]: Contact };
          conversations: { [key: string]: UIConversation };
        };
      }
    ) => {
      state.contacts = action.payload.contacts;
      // todo update conversations messages without overwriting
      state.conversations = action.payload.conversations;

      state.runtime.initialLoadComplete = true;
      const messageCounts = Object.values(action.payload.conversations).map(
        el => el.unreadMessageCount
      );
      state.unreadMessageCount = messageCounts.reduce((acc, count) => acc + count, 0);
    },
    updateConversationAndDraftText: (
      state,
      action: {
        payload: { contactId: string; messages: UIMessage[]; draftMessage: string | undefined };
      }
    ) => {
      action.payload.messages.forEach(message => {
        if (
          !state.conversations[action.payload.contactId].messages.some(m => m.id === message.id)
        ) {
          state.conversations[action.payload.contactId].messages.push(message);
        }
      });

      state.conversations[action.payload.contactId].messages.sort(
        (a, b) => a.timestamp - b.timestamp
      );

      if (action.payload.draftMessage !== undefined) {
        state.runtime.draftMessages[action.payload.contactId] = action.payload.draftMessage;
      }
    },
    updateIsAllMessagesLoadedForConversation: (
      state,
      action: { payload: { contactId: string; isAllMessagesLoaded: boolean } }
    ) => {
      state.conversations[action.payload.contactId].isAllMessagesLoaded =
        action.payload.isAllMessagesLoaded;
    },
    updateLastReadMessageForContact: (
      state,
      action: { payload: { contactId: string; messageId: string } }
    ) => {
      state.readMessageMap[action.payload.contactId] = action.payload.messageId;
    },
    updateUnreadMessageCountForContact: (
      state,
      action: { payload: { contactId: string; count: number } }
    ) => {
      const contact = state.contacts[action.payload.contactId];
      if (contact) {
        const conversation = state.conversations[contact.id];
        if (conversation) {
          conversation.unreadMessageCount = action.payload.count;
        }
      }
    },
    updateTotalUnreadMessageCount: (state, action: { payload: { unreadMessageCount: number } }) => {
      state.unreadMessageCount = action.payload.unreadMessageCount;
    }
  }
});

export const {
  setActiveContactId,
  setDraftMessage,
  setContactsAndConversations,
  updateConversationAndDraftText,
  setContactFilterText,
  resetFilteredTextAndCategory,
  updateLastReadMessageForContact,
  setContactFilteredCategory,
  updateUnreadMessageCountForContact,
  updateTotalUnreadMessageCount,
  updateIsAllMessagesLoadedForConversation
} = spiderTxtSlice.actions;

export default spiderTxtSlice;
