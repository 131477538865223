import React, { useEffect, useState } from 'react';
import { Button, PageHeader, Tabs } from 'antd';
import { useHistory, useParams } from 'react-router';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import { useFeatureFlag } from '../../../hooks';
import { useLocation } from 'react-router-dom';
import {
  InvitedUser,
  OrganisationGroup,
  OrganisationMember,
  UserService
} from '../../../common/api/spidertracks-sdk/private/services/UserService';
import { PersonAssociatedTag } from '../../../common/api/spidertracks-sdk/types/PersonAssociatedTag';
import { PublicAircraftData } from '../../../common/api/spidertracks-sdk/types/AircraftData';
import {
  showErrorNotification,
  showSuccessNotification
} from '../../../helpers/globalNotifications';
import { InvitedUsersContainer } from './invited/InvitedUsersContainer';
import InviteUsersDrawer from './invited/InviteUsersDrawer';
import OrganisationMemberDrawer from './members/OrganisationMemberDrawer';
import { OrganisationMembersContainer } from './members/OrganisationMembersContainer';
import { OrganisationGroupsContainer } from './groups/OrganisationGroupsContainer';
import OrganisationGroupDrawer from './groups/OrganisationGroupDrawer';

const style = {
  overflow: 'scroll',
  height: '100%'
};
const { TabPane } = Tabs;

const UsersContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  const { organisationId } = useParams<{ organisationId: string }>();
  const isUserLevelSpidertxtEnabled = useFeatureFlag('spidertxt-users-admin', [organisationId]);
  const isTagsAdminFlagEnabled = useFeatureFlag('manifest-tags-admin', [organisationId]);
  const isGroupsAdminEnabled = useFeatureFlag('groups-users-admin', [organisationId]);
  const isAircraftVisibilityGroupsAdminEnabled = useFeatureFlag('aircraft-groups-users-admin', [
    organisationId
  ]);
  const isNotificationsGroupsAdminEnabled = useFeatureFlag('notifications-groups-users-admin', [
    organisationId
  ]);

  const [loadingInvitedUsers, setLoadingInvitedUsers] = useState<boolean>(false);
  const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>([]);
  const [loadingOrganisationMembers, setLoadingOrganisationMembers] = useState<boolean>(false);
  const [organisationMembers, setOrganisationMembers] = useState<OrganisationMember[]>([]);
  const [loadingOrgGroups, setLoadingOrgGroups] = useState<boolean>(false);
  const [organisationGroups, setOrganisationGroups] = useState<OrganisationGroup[]>([]);

  const [tags, setTags] = useState<PersonAssociatedTag[]>([]);
  const [organisationAircraft, setOrganisationAircraft] = useState<PublicAircraftData[]>([]);
  const [selectedOrganisationMember, setSelectedOrganisationMember] = useState<
    OrganisationMember
  >();
  const [selectedOrganisationGroup, setSelectedOrganisationGroup] = useState<OrganisationGroup>();
  const [memberDrawerVisible, setMemberDrawerVisible] = useState<boolean>(false);
  const [inviteDrawerVisible, setInviteDrawerVisible] = useState<boolean>(false);
  const [groupDrawerVisible, setGroupDrawerVisible] = useState<boolean>(false);
  const [currentActiveKey, setCurrentActiveKey] = useState(
    pathname.slice(pathname.lastIndexOf('/') + 1)
  );

  useEffect(() => {
    history.listen(location => {
      const { pathname } = location;
      setCurrentActiveKey(pathname.slice(pathname.lastIndexOf('/') + 1));
    });
  }, [history]);

  const loadInvitedUsers = async () => {
    await getInstance()
      .getUserService()
      .getInvitedUsers(organisationId)
      .then(users => setInvitedUsers(users))
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to fetch invited users');
      })
      .finally(() => setLoadingInvitedUsers(false));
  };

  const loadOrganisationMembers = async () => {
    await getInstance()
      .getUserService()
      .getOrganisationMembers(organisationId)
      .then(members => setOrganisationMembers(members))
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to fetch organisation members');
      })
      .finally(() => setLoadingOrganisationMembers(false));
  };

  const fetchGroupsWithMembers = async (userService: UserService) => {
    const groups = await userService.getOrganisationGroups(organisationId);
    return Promise.all(
      groups.map(group =>
        userService.getOrganisationGroupMembers(organisationId, group.id).then(members => ({
          ...group,
          members
        }))
      )
    );
  };

  const loadGroups = async () => {
    setLoadingOrgGroups(true);
    try {
      const updatedGroupsWithMembers = await fetchGroupsWithMembers(getInstance().getUserService());
      setOrganisationGroups(updatedGroupsWithMembers);
    } catch (error) {
      console.error(error);
      showErrorNotification('Failed to fetch groups');
    } finally {
      setLoadingOrgGroups(false);
    }
  };

  const sendInvites = async (emailAddresses: string[], customMessage?: string) => {
    setLoadingInvitedUsers(true);
    await getInstance()
      .getUserService()
      .sendInvites(organisationId, emailAddresses, customMessage)
      .then(() =>
        showSuccessNotification(emailAddresses.length > 1 ? 'Invites sent' : 'Invite sent')
      )
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to send invites');
      });
    setInviteDrawerVisible(false);
    await loadInvitedUsers();
  };

  const cancelInvites = async (inviteIds: string[]) => {
    setLoadingInvitedUsers(true);
    await getInstance()
      .getUserService()
      .cancelInvites(organisationId, inviteIds)
      .then(() =>
        showSuccessNotification(inviteIds.length > 1 ? 'Invites cancelled' : 'Invite cancelled')
      )
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to cancel invites');
      });
    await loadInvitedUsers();
  };

  useEffect(() => {
    const userService = getInstance().getUserService();

    const fetchInvitedUsers = async () => {
      await userService.getInvitedUsers(organisationId).then(users => {
        setInvitedUsers(users);
      });
    };

    const fetchOrganisationMembers = async () => {
      const [members, tags, organisationAircraft] = await Promise.all([
        userService.getOrganisationMembers(organisationId),
        getInstance().getPersonAssociatedTags(organisationId),
        getInstance().getOrgAircraft(organisationId)
      ]);
      const formattedTags = tags.map(tag => {
        return {
          ...tag,
          macAddress:
            tag && tag.macAddress
              ? tag.macAddress
                  .replace(/:/g, '')
                  .replace(/-/g, '')
                  .replace(/(.{2})/g, '$1:')
                  .replace(/:$/g, '')
              : '-'
        };
      });
      setOrganisationMembers(members);
      setTags(formattedTags);
      setOrganisationAircraft(organisationAircraft);
    };

    const fetchOrganisationGroups = async () => {
      const updatedGroupsWithMembers = await fetchGroupsWithMembers(userService);
      setOrganisationGroups(updatedGroupsWithMembers);
    };

    setLoadingInvitedUsers(true);
    setLoadingOrganisationMembers(true);
    setLoadingOrgGroups(true);

    fetchInvitedUsers()
      .catch(console.error)
      .finally(() => {
        setLoadingInvitedUsers(false);
      });
    fetchOrganisationMembers()
      .catch(console.error)
      .finally(() => {
        setLoadingOrganisationMembers(false);
      });
    fetchOrganisationGroups()
      .catch(console.error)
      .finally(() => {
        setLoadingOrgGroups(false);
      });
  }, [organisationId]);

  useEffect(() => {
    if (selectedOrganisationMember) {
      setMemberDrawerVisible(true);
    }
  }, [selectedOrganisationMember]);

  useEffect(() => {
    if (selectedOrganisationGroup) {
      setGroupDrawerVisible(true);
    }
  }, [selectedOrganisationGroup]);

  const handleOrganisationMemberRowClick = (organisationMemberId: string) => {
    if (organisationMemberId) {
      const member = organisationMembers.find(m => m.id === organisationMemberId);
      if (member) {
        setSelectedOrganisationMember(member);
      }
    }
  };

  const handleOrganisationGroupRowClick = (organisationGroupId: string) => {
    if (organisationGroupId) {
      const group = organisationGroups.find(m => m.id === organisationGroupId);
      if (group) {
        setSelectedOrganisationGroup(group);
      }
    }
  };

  const handleMemberDrawerClose = () => {
    setMemberDrawerVisible(false);
    setSelectedOrganisationMember(undefined);
  };

  const handleGroupDrawerClose = () => {
    setGroupDrawerVisible(false);
    setSelectedOrganisationGroup(undefined);
  };

  const onSaveMember = async (success: boolean) => {
    setMemberDrawerVisible(false);
    if (success) {
      showSuccessNotification('Member updated successfully');
    } else {
      showErrorNotification('Failed to update member');
    }
    setSelectedOrganisationMember(undefined);
    await loadOrganisationMembers();
  };

  const onSaveGroup = async (success: boolean, actionType: string) => {
    handleGroupDrawerClose();
    if (success) {
      showSuccessNotification(`Group ${actionType} successfully`);
    } else {
      showErrorNotification(`Failed to ${actionType} group`);
    }
    setSelectedOrganisationGroup(undefined);
    await loadGroups();
  };

  const removeOrganisationMembers = async (memberIds: string[]) => {
    setLoadingOrganisationMembers(true);
    await getInstance()
      .getUserService()
      .deleteOrganisationMembers(memberIds, organisationId)
      .then(() =>
        showSuccessNotification(
          memberIds.length > 1 ? 'Members removed successfully' : 'Member removed successfully'
        )
      )
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to remove members');
      });
    handleMemberDrawerClose();
    await loadOrganisationMembers();
  };

  const removeOrganisationGroup = async (groupId: string) => {
    setLoadingOrgGroups(true);
    await getInstance()
      .getUserService()
      .deleteOrganisationGroup(organisationId, groupId)
      .then(() => showSuccessNotification('Group removed successfully'))
      .catch(error => {
        console.error(error);
        showErrorNotification('Failed to remove group');
      });
    handleGroupDrawerClose();
    await loadGroups();
  };

  const onTabChange = (activeKey: string): void => {
    const newPath = pathname.slice(0, pathname.lastIndexOf('/')).concat(`/${activeKey}`);
    history.push(newPath);
  };

  return (
    <div className="px-5 py-4" style={style}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <PageHeader
          className="site-page-header"
          style={{ paddingLeft: '0px' }}
          title="Users & Groups"
          subTitle=""
        />
        <Button
          type="primary"
          size="large"
          data-testid={currentActiveKey === 'users' ? 'invite-button' : 'add-button'}
          onClick={() =>
            currentActiveKey === 'users'
              ? setInviteDrawerVisible(true)
              : setGroupDrawerVisible(true)
          }
          style={{ padding: '0 50px', width: '140px' }}
        >
          {currentActiveKey === 'users' ? 'Invite' : 'Add'}
        </Button>
      </div>
      <Tabs defaultActiveKey="users" onChange={onTabChange} activeKey={currentActiveKey}>
        <TabPane tab="Users" key="users">
          <InvitedUsersContainer
            invitedUsers={invitedUsers}
            loading={loadingInvitedUsers}
            sendInvites={sendInvites}
            cancelInvites={cancelInvites}
          />
          <OrganisationMembersContainer
            organisationMembers={organisationMembers}
            tags={tags}
            loading={loadingOrganisationMembers}
            onRow={handleOrganisationMemberRowClick}
            removeOrganisationMembers={removeOrganisationMembers}
            showTableTitle={invitedUsers.length > 0 || loadingInvitedUsers}
            isUserLevelSpidertxtEnabled={isUserLevelSpidertxtEnabled}
            isTagsAdminFlagEnabled={isTagsAdminFlagEnabled}
            isGroupsAdminEnabled={isGroupsAdminEnabled}
          />
        </TabPane>
        {isGroupsAdminEnabled && (
          <TabPane tab="Groups" key="groups">
            <OrganisationGroupsContainer
              groups={organisationGroups}
              loading={loadingOrgGroups}
              isAircraftVisibilityGroupsAdminEnabled={isAircraftVisibilityGroupsAdminEnabled}
              isNotificationsGroupsAdminEnabled={isNotificationsGroupsAdminEnabled}
              onRow={handleOrganisationGroupRowClick}
            />
          </TabPane>
        )}
        {!isGroupsAdminEnabled && (
          <TabPane tab="Groups (coming soon)" disabled key="groups-placeholder" />
        )}
      </Tabs>
      {memberDrawerVisible && selectedOrganisationMember !== undefined && (
        <OrganisationMemberDrawer
          organisationMember={selectedOrganisationMember}
          allTags={tags}
          personTag={tags.find(tag => tag.personId === selectedOrganisationMember.id)}
          organisationAircraft={organisationAircraft}
          onClose={handleMemberDrawerClose}
          onSave={onSaveMember}
          removeOrganisationMembers={removeOrganisationMembers}
          isUserLevelSpidertxtEnabled={isUserLevelSpidertxtEnabled}
          isTagsAdminFlagEnabled={isTagsAdminFlagEnabled}
        />
      )}
      {inviteDrawerVisible && (
        <InviteUsersDrawer
          onClose={() => setInviteDrawerVisible(false)}
          onCreate={(emailAddresses: string, customMessage?: string) => {
            sendInvites(
              emailAddresses
                .toLowerCase()
                .split(',')
                .map(email => email.trim()),
              customMessage
            );
          }}
        />
      )}
      {isGroupsAdminEnabled && groupDrawerVisible && (
        <OrganisationGroupDrawer
          organisationGroup={selectedOrganisationGroup}
          organisationAircraft={organisationAircraft}
          organisationMembers={organisationMembers}
          isAircraftVisibilityGroupsAdminEnabled={isAircraftVisibilityGroupsAdminEnabled}
          isNotificationsGroupsAdminEnabled={isNotificationsGroupsAdminEnabled}
          removeOrganisationGroup={removeOrganisationGroup}
          onSave={onSaveGroup}
          onClose={handleGroupDrawerClose}
        />
      )}
    </div>
  );
};

export default UsersContainer;
