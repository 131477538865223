import React from 'react';
import { Contact, ContactType } from '../../types/spidertxt';
import { Avatar } from '@chatscope/chat-ui-kit-react';

interface ContactAvatarProps {
  as: string | typeof Avatar;
  contact: Contact;
}

/** Handles group / contact default avatar loading */
export const ContactAvatar: React.FC<ContactAvatarProps> = props => {
  const { contact } = props;
  // Groups don't have avatars yet
  let avatarUrl = '/spidertxt-images/default-group.png';
  if (contact.contactType == ContactType.INDIVIDUAL) {
    if (contact.avatarUrl) {
      avatarUrl = contact.avatarUrl;
    } else {
      avatarUrl = '/spidertxt-images/default-user.png';
    }
  }

  return <Avatar name={undefined} src={avatarUrl} />;
};
