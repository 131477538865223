import React from 'react';
import { MessageType, UIMessage } from '../../types/spidertxt';
import moment from 'moment';

interface SystemMessageProps {
  message: UIMessage;
}

const SystemMessage: React.FC<SystemMessageProps> = ({ message }) => {
  const systemMessageCSS: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0em auto',
    backgroundColor: '#E6F7FF',
    padding: '0.25em',
    borderRadius: '10px',
    width: 'fit-content',
    marginBottom: '0.5em'
  };
  const systemMessageContentCSS: React.CSSProperties = {
    margin: '0'
  };
  const systemMessageTimeStampCSS: React.CSSProperties = {
    fontSize: 'x-small',
    marginLeft: 'auto',
    paddingRight: '0.25em'
  };

  if (message.type != MessageType.SYSTEM) {
    return <></>;
  }
  const time = new Date(message.timestamp);
  let timeString = moment(time).format('HH:mm');

  return (
    <div style={systemMessageCSS}>
      <div style={systemMessageContentCSS} className="cs-message--incoming cs-message__content">
        {message.body}
      </div>
      <div style={systemMessageTimeStampCSS} className="cs-message__footer cs-message__sent-time">
        {timeString}
      </div>
    </div>
  );
};

export default SystemMessage;
