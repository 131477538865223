import React, { useState } from 'react';
import { getContacts, getActiveContactId, getConversations } from '../../redux/selectors/spiderTxt';
import { useSelector } from 'react-redux';
import { ContactType } from '../../types/spidertxt';

const ConversationHeaderCollapsible: React.FC = () => {
  const activeConversationId = useSelector(getActiveContactId);
  const contacts = useSelector(getContacts);
  const conversations = useSelector(getConversations);

  const activeContact = contacts.find(contact => contact.id === activeConversationId);
  const activeConversation = conversations.find(
    conversation => conversation.contactId === activeConversationId
  );
  if (activeContact === undefined || activeConversation === undefined) {
    return <></>;
  }
  const [isOpen, setIsOpen] = useState(activeConversation.messages.length > 0 ? false : true);
  const changeOpenState = () => {
    setIsOpen(!isOpen);
  };

  if (activeContact.contactType == ContactType.GROUP) {
    const groupMembers = activeContact.memberIds
      .map(memberId => contacts.find(contact => contact.id === memberId))
      .filter(contact => contact !== undefined);
    return (
      <div className={`cs-expansion-panel ${isOpen ? 'cs-expansion-panel--open' : ''}`}>
        <div className="cs-expansion-panel__header">
          <div
            onClick={changeOpenState}
            className="cs-expansion-panel__icon"
            style={{
              transform: isOpen ? 'rotate(180deg)' : '',
              transition: 'transform 150ms ease'
            }}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-down"
              className="svg-inline--fa fa-chevron-down fa-w-14 "
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              ></path>
            </svg>
          </div>
          <div className="cs-expansion-panel__title">{activeContact.displayName}</div>
        </div>
        <div className="cs-expansion-panel__content">
          <div className="cs-expansion-panel__content-centered">
            <div className="cs-expansion-panel__content_label">ORGANISATION</div>
            <div className="cs-expansion-panel__content_value">
              {activeContact.organisationName}
            </div>

            <div className="cs-expansion-panel__content_label">MEMBERS</div>
            <div className="cs-expansion-panel__content_value">
              {groupMembers.map(member => (
                <div key={member.id}>{member.displayName}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <div className={'cs-conversation-header__user-name'}>{activeContact.displayName}</div>;
};

export default ConversationHeaderCollapsible;
