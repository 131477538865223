import React from 'react';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';

interface ComponentProps {
  memberIds: string[];
  setIsSaving: (isSaving: boolean) => void;
  removeOrganisationMembers: (memberIds: string[]) => void;
}

const getTitle = (memberIds: string[]) =>
  memberIds.length > 1 ? 'Remove Members' : 'Remove Member';

const getContent = (memberIds: string[]) =>
  memberIds.length > 1 ? (
    <>
      <p>Are you sure you want to remove the selected members?</p>
      <p>
        This will remove the members from the organisation. You would need to send a fresh invite to
        add the users back into the organisation.
      </p>
    </>
  ) : (
    <>
      <p>Are you sure you want to delete the selected member?</p>
      <p>
        This will remove the member from the organisation. You would need to send a fresh invite to
        add the user back into the organisation.
      </p>
    </>
  );

export const showRemoveOrganisationMembersModal = ({
  memberIds,
  setIsSaving,
  removeOrganisationMembers
}: ComponentProps) =>
  Modal.confirm({
    title: getTitle(memberIds),
    icon: <ExclamationCircleFilled />,
    content: getContent(memberIds),
    okText: memberIds.length > 1 ? 'Remove Members' : 'Remove Member',
    okButtonProps: { type: 'danger' },
    onOk() {
      setIsSaving(true);
      removeOrganisationMembers(memberIds);
    }
  });
