import { DisplayPoint } from '../common/api/spidertracks-sdk/types/TrackData';
import { CompleteFlightDataSamples } from '../types/FlightData';
import { EventClass, FlightStatus } from '../constants';
import { FullState } from '../store';
import { AircraftBase } from '../types/aircraft';
import { Track } from '../types/Track';
import { Moment } from 'moment';
import { UserData as SdkUserData } from '../common/api/spidertracks-sdk/types/UserData';

export interface AircraftState {
  aircraftList: Aircraft[];
  visibleAircraft: string[];
  selectedAircraft: string[];
  selectedTrackId: string | undefined;
  trackData: Track[];
  isLoading: boolean;
}

// NOTE: has a very particular format in the API response, probably not a good candidate for a
//  "general" organisation type
export interface AircraftOrganisation {
  id: string;
  isPublic: boolean;
  orgName: string;
}

export interface Aircraft extends AircraftBase {
  includes(id: string): unknown;

  isActive: boolean;
  mostRecentTrack: Track;
  trackStatus: string;
  lastPointTimestamp: number;
  org: AircraftOrganisation;
}

// NOTE: duplicates src/types/Track
// export interface Track {
//   aircraft: Aircraft;
//   id: number; // for DB use only
//   trackId: string;
//   points: Point[];
// }

export interface Point {
  longitude: number;
  latitude: number;
  id: number | string;
  timestamp: number;
  altitude: number;
  altitudeUnit?: string;
  pointType?: string;
}

export interface SosNotification {
  id: string;
  tier: 'ONE' | 'TWO';
}

export type MapState = FullState['mapReducer'];

export type NavigationState = FullState['navigationReducer'];

export type UserData = SdkUserData;

// TODO: duplicates common/api/spidertracks-sdk/types/Event
export interface Event {
  aircraftId: string;
  eventClass: EventClass;
  eventId: string;
  eventDetails: Record<string, any>;
  eventTime: string;
  eventType: string;
  geohash: string;
  organisationId: string;
}

export interface CASEvent extends Event {
  eventDetails: Record<string, any> & {
    crewAlertEvent: {
      severity: 'WARNING' | 'CAUTION' | 'ADVISORY';
      subtype: 'ASSERTED' | 'CLEARED';
    };
  };
}

export interface EventState {
  aircraft: Record<string, Event>;
  flightStatus: Record<string, FlightStatus>;
  selectedDisplayPoint: DisplayPoint | undefined;
  // TODO: fix
  types: Record<string, any>;
}

export enum PanelVisibility {
  VISIBLE = 'VISIBLE',
  HIDDEN = 'HIDDEN',
  DISABLED = 'DISABLED'
}

export interface FlightExplorer3DReplayFeatures {
  showEvents: boolean;
  showWall: boolean;
}

export type PanelVisibilityKeys =
  | 'flightEventsTimeline'
  | 'flightReportSummaryChart'
  | '2dmap'
  | '3dfr';

export interface ChartSeries {
  left: string[];
  right: string[];
}

export interface FlightExplorerState {
  isPlaying: boolean;
  playbackSpeed: number;
  elapsedTime: number;
  totalTime: number;
  updateSelectedDisplayPoint: boolean;
  displayTimezone: string;
  events: {
    selectedDisplayPoint: Point | null;
  };
  panelVisibility: {
    flightEventsTimeline: PanelVisibility;
    flightReportSummaryChart: PanelVisibility;
    '2dmap': PanelVisibility;
    '3dfr': PanelVisibility;
  };
  '3dReplayFeatures': FlightExplorer3DReplayFeatures;
  selectedTrackFlightData: CompleteFlightDataSamples;
  isFlightDataFetchingInProgress: boolean;
  chartSeries: ChartSeries;
  // TODO Remove once GA
  dataSource: string;
}

export interface TrackStatusPayload {
  trackStatus: TrackStatus[];
  init?: boolean;
}

export interface TrackStatus {
  id: number;
  '3dReplayAvailable': boolean;
}

export interface FilterConfig {
  aircraft?: string[];
  aircraftMoved?: boolean;
  sos?: ('Active' | 'Closed')[];
  spiderTimeGreaterThanOneMinute?: boolean;
}

export interface HistoryTracksState {
  trackStatus: TrackStatus[];
  filterConfig: FilterConfig;
}

//NOTE: the UI splits on the first `__` between the category and the values in it.
// Also, some events are explicitly not listed here as we don't want to show them to the users.
export enum EventSubtype {
  /* eslint-disable @typescript-eslint/camelcase */
  // [sic] https://github.com/Spidertracks/ingest.pipeline/blob/master/src/packages/schema/lib/schema/events/insights.ts#L14-L30
  EMERGENCY__SOS = 'SOS',
  EXCEEDANCE__ROC_EXCEEDED = 'ROC_EXCEEDED',
  EXCEEDANCE__ROC_NORMAL = 'ROC_NORMAL',
  EXCEEDANCE__ROD_EXCEEDED = 'ROD_EXCEEDED',
  EXCEEDANCE__ROD_NORMAL = 'ROD_NORMAL',
  EXCEEDANCE__excessive_pitch_down = 'excessive_pitch_down',
  EXCEEDANCE__excessive_pitch_down_at_low_altitude_agl = 'excessive_pitch_down_at_low_altitude_agl',
  EXCEEDANCE__excessive_pitch_up = 'excessive_pitch_up',
  EXCEEDANCE__excessive_pitch_up_at_low_speed = 'excessive_pitch_up_at_low_speed',
  EXCEEDANCE__excessive_pitch_up_at_low_altitude_agl = 'excessive_pitch_up_at_low_altitude_agl',
  EXCEEDANCE__excessive_rate_of_climb = 'excessive_rate_of_climb',
  EXCEEDANCE__excessive_roll = 'excessive_roll',
  EXCEEDANCE__excessive_roll_at_low_altitude = 'excessive_roll_at_low_altitude',
  EXCEEDANCE__excessive_roll_at_low_altitude_agl = 'excessive_roll_at_low_altitude_agl',
  EXCEEDANCE__high_rate_of_descent = 'high_rate_of_descent',
  EXCEEDANCE__high_rate_of_descent_at_low_altitude = 'high_rate_of_descent_at_low_altitude',
  EXCEEDANCE__high_rate_of_descent_at_low_altitude_agl = 'high_rate_of_descent_at_low_altitude_agl',
  EXCEEDANCE__maximum_altitude_agl = 'maximum_altitude_agl',
  EXCEEDANCE__maximum_altitude_amsl = 'maximum_altitude_amsl',
  EXCEEDANCE__excessive_g_force = 'excessive_g_force',
  FLIGHT_LOGS__BLOCKS_OFF = 'BLOCKS_OFF',
  FLIGHT_LOGS__BLOCKS_ON = 'BLOCKS_ON',
  FLIGHT_LOGS__LANDING = 'LANDING',
  FLIGHT_LOGS__TAKEOFF = 'TAKEOFF',
  FLIGHT_LOGS__SPEED_UP = 'SPEED_UP',
  FLIGHT_LOGS__SPEED_DOWN = 'SPEED_DOWN',
  /* eslint-enable @typescript-eslint/camelcase */

  FIRE_FIGHTING__DROP_END = 'DROP_END',
  FIRE_FIGHTING__DROP_START = 'DROP_START',
  FIRE_FIGHTING__FILL_END = 'FILL_END',
  FIRE_FIGHTING__FILL_START = 'FILL_START',
  FIRE_FIGHTING__PUMP_OFF = 'PUMP_OFF',
  FIRE_FIGHTING__PUMP_ON = 'PUMP_ON',
  CUSTOM__MARK_1 = 'MARK1',
  CUSTOM__MARK_2 = 'MARK2',
  CUSTOM__MARK_3 = 'MARK3',
  CUSTOM__MARK_4 = 'MARK4'

  //LATER: re-add these once they are in use.
  //CREW_ALERTS__ASSERTED = 'ASSERTED',
  //CREW_ALERTS__CLEARED = 'CLEARED'
}

//NOTE: the UI splits on the first `__` between the category and the values in it.
export enum EventSeverity {
  //NOTE: the API maps this value to `null` in the DB //FIXME: change the API from using `UNSPECIFIED` to this:
  INSIGHTS__NOT_SPECIFIED = 'NOT_SPECIFIED',
  INSIGHTS__LOW = 'LOW',
  INSIGHTS__MEDIUM = 'MEDIUM',
  INSIGHTS__HIGH = 'HIGH'
  /*  //LATER: #AVIONICS add these when needed:
  AVIONICS__WARNING = 'WARNING',
  AVIONICS__CAUTION = 'CAUTION',
  AVIONICS__ADVISORY = 'ADVISORY'
  */
}

export interface EventsExplorerState {
  dateFilter: {
    startTime: Moment;
    endTime: Moment;
  };
  organisations: string[];
  aircraft: string[];
  eventSubtypes: EventSubtype[];
  eventSeverities: EventSeverity[];
  loadingStatus: {
    events: {
      cancel: boolean;
      loading: boolean;
      total?: number;
      loaded: number;
      flights: number;
      error?: Error;
    };
  };
  mapTheme: string;
}
