import React, { useEffect, useState } from 'react';
import { Form, Drawer } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import {
  OrganisationGroup,
  OrganisationMember
} from '../../../../common/api/spidertracks-sdk/private/services/UserService';
import {
  PrivateAircraftData,
  PublicAircraftData
} from '../../../../common/api/spidertracks-sdk/types/AircraftData';
import { OrganisationGroupForm, OrganisationGroupFormData } from './OrganisationGroupForm';

interface ComponentProps extends FormComponentProps {
  organisationGroup: OrganisationGroup | undefined;
  organisationAircraft: (PublicAircraftData | PrivateAircraftData)[];
  organisationMembers: OrganisationMember[];
  isAircraftVisibilityGroupsAdminEnabled: boolean;
  isNotificationsGroupsAdminEnabled: boolean;
  removeOrganisationGroup: (groupId: string) => void;
  onClose: () => void;
  onSave: (success: boolean, actionType: string) => void;
}

const OrganisationGroupDrawer = ({
  organisationGroup,
  organisationAircraft,
  organisationMembers,
  isAircraftVisibilityGroupsAdminEnabled,
  isNotificationsGroupsAdminEnabled,
  removeOrganisationGroup,
  onSave,
  onClose,
  form
}: ComponentProps) => {
  const [formData, setFormData] = useState<OrganisationGroupFormData | null>(null);

  useEffect(() => {
    if (organisationGroup) {
      setFormData({
        groupId: organisationGroup.id,
        groupName: organisationGroup.name,
        groupMembers: organisationGroup.members || [],
        organisationAircraft: organisationAircraft
          .map(aircraft => ({
            label: aircraft.registration,
            value: aircraft.id,
            disabled: false
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        // To be updated once the backend supports aircraft visibility for groups
        groupSelectedAircraft: [],
        notificationsAvailable: organisationGroup.notificationsAvailable,
        spiderTxtAvailable: organisationGroup.textingAvailable,
        status: organisationGroup.active
      });
    }
  }, [organisationGroup, organisationAircraft]);

  return (
    <Drawer
      data-testid={'organisation-group-edit-drawer'}
      title={organisationGroup ? 'Edit' : 'Add'}
      placement="right"
      onClose={onClose}
      visible={true}
      width={400}
    >
      <div style={{ marginLeft: '3em' }}>
        <div style={{ paddingTop: '2em' }}>
          <OrganisationGroupForm
            initialData={formData}
            organisationMembers={organisationMembers}
            isAircraftVisibilityGroupsAdminEnabled={isAircraftVisibilityGroupsAdminEnabled}
            isNotificationsGroupsAdminEnabled={isNotificationsGroupsAdminEnabled}
            onClose={onClose}
            onSave={onSave}
            removeOrganisationGroup={removeOrganisationGroup}
            form={form}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default Form.create<ComponentProps>()(OrganisationGroupDrawer);
