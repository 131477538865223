import { getInstance } from '../../../common/api/spidertracks-sdk';
import { StandardThunk } from '../../../store';
import {
  setContactsAndConversations,
  updateConversationAndDraftText,
  updateLastReadMessageForContact,
  setDraftMessage,
  updateIsAllMessagesLoadedForConversation
} from './spiderTxt';
import { getContacts, getConversations, getReadMessageMap } from '../../selectors/spiderTxt';
import { getUserData } from '../../selectors/userData';
import {
  Contact,
  ContactType,
  Message,
  MessageType,
  UIConversation,
  UIMessage
} from '../../../types/spidertxt';
import { UserData } from '../../types';

const getUIMessages = (messages: Message[], contact: Contact[]): UIMessage[] => {
  return messages.map(message => {
    const sender = contact.find(contact => contact.id === message.senderId);
    return {
      ...message,
      senderDisplayName: sender
        ? sender.displayName
        : 'Unknown Sender (Please refresh your browser)'
    };
  });
};

const getCurrentUserContact = (userData: UserData): Contact => {
  return {
    id: userData.id,
    displayName: userData.displayName,
    contactType: ContactType.INDIVIDUAL,
    avatarUrl: undefined,
    organisations: []
  };
};

export const populateContactsAndConversations = (): StandardThunk<void> => async (
  dispatch,
  getState
) => {
  const sdk = getInstance();
  const currentUser = getUserData(getState());

  const contactsPromise = sdk.getContactService().getContacts();
  const allConversations = await sdk
    .getConversationService()
    .getAllActiveConversations(currentUser.id);
  const contacts = await contactsPromise;
  const payload: {
    contacts: { [key: string]: Contact };
    conversations: { [key: string]: UIConversation };
  } = { contacts: {}, conversations: {} };

  contacts.forEach(contact => {
    payload.contacts[contact.id] = contact;
  });

  allConversations.forEach(conversation => {
    if (conversation.contactId == currentUser.id) {
      return;
    }
    const contact = contacts.find(contact => contact.id === conversation.contactId);
    if (!contact) {
      // We sometimes get conversations for contacts that don't exist. We don't want their conversations.
      return;
    }
    payload.conversations[conversation.contactId] = {
      ...conversation,
      messages: getUIMessages(conversation.messages, contacts),
      isAllMessagesLoaded: false
    };
  });
  dispatch(setContactsAndConversations(payload));
};

/*
 * Get more messages for a conversation
 */
export const getMoreMessagesForConversation = (
  contactId: string,
  loadingStatusCallback: Function
): StandardThunk<void> => async (dispatch, getState) => {
  const sdk = getInstance();
  const allContacts = getContacts(getState());
  const currentUser = getUserData(getState());
  const allConversations = getConversations(getState());

  const loggedInUserContact = getCurrentUserContact(currentUser);
  const conversation = allConversations.find(conversation => conversation.contactId === contactId);

  if (!conversation || !loggedInUserContact) {
    console.error('Could not find the conversation');
    throw new Error('Could not find the conversation');
  }

  const { isAllMessagesLoaded } = conversation;
  const lastMessage = conversation.messages[0];

  if (!isAllMessagesLoaded) {
    const messages = await sdk
      .getConversationService()
      .getMoreMessagesForContact(loggedInUserContact.id, contactId, lastMessage.id);

    if (messages.length === 0) {
      dispatch(
        updateIsAllMessagesLoadedForConversation({
          contactId: contactId,
          isAllMessagesLoaded: true
        })
      );
    } else {
      dispatch(
        updateConversationAndDraftText({
          contactId: contactId,
          messages: getUIMessages(messages, allContacts),
          draftMessage: undefined
        })
      );
    }
  }

  loadingStatusCallback(false);
};

/* to be triggered when a new message is received(on spidertxt event) */
export const updateReceivedMessages = (message: Message): StandardThunk<void> => async (
  dispatch,
  getState
) => {
  const allContacts = getContacts(getState());

  dispatch(
    updateConversationAndDraftText({
      contactId: message.contactId,
      messages: getUIMessages([message], allContacts),
      draftMessage: undefined
    })
  );
};

/* We send the message and then fetch last 'n' messages for that conversation from the API and update our store */
export const sendMessage = (
  contactId: string,
  messageBody: string,
  callback: Function
): StandardThunk<void> => async (dispatch, getState) => {
  const allContacts = getContacts(getState());
  const currentUser = getUserData(getState());

  const contact = allContacts.find(contact => contact.id === contactId);

  if (!contact) {
    console.error('Could not find contact');
    return Promise.reject('Could not find contact');
  }

  const conversationService = getInstance().getConversationService();
  try {
    await conversationService.sendMessage({ contactId, body: messageBody });
  } catch (e) {
    console.error('Error sending message', e);
    return;
  } finally {
    callback();
  }

  // fetch the last 5 messages for the conversation( This includes the message we just sent)
  const messages = await conversationService.getMoreMessagesForContact(
    currentUser.id,
    contactId,
    undefined,
    5
  );

  dispatch(
    updateConversationAndDraftText({
      contactId: contactId,
      messages: getUIMessages(messages, allContacts),
      draftMessage: ''
    })
  );
};

export const markMessagesAsRead = (contactId: string): StandardThunk<void> => async (
  dispatch,
  getState
) => {
  const readMessageMap = getReadMessageMap(getState());
  const allConversations = getConversations(getState());
  const conversation = allConversations.find(conversation => conversation.contactId === contactId);

  if (!conversation) {
    console.error('Could not find conversation');
    throw new Error('Could not find conversation');
  }

  const lastMessage = conversation.messages.filter((message: Message) => {
    return message.type === MessageType.RECEIVED;
  })[0];

  if (!lastMessage) {
    return;
  }

  const lastReadMessageId = readMessageMap[contactId];

  if (lastReadMessageId === lastMessage.id) {
    return;
  } else {
    dispatch(updateLastReadMessageForContact({ contactId, messageId: lastMessage.id }));
  }

  const conversationService = getInstance().getConversationService();
  await conversationService.acknowledgeMessage(contactId, lastMessage.id);
};
