import React, { Fragment } from 'react';
import { useParams, Route } from 'react-router';
import { useGlobalConfig } from '../../hooks/useGlobalConfig';
import { ORGANISATION, OLD_SETTINGS_PAGES } from '../../common/constants';
import OrgSettingsMenu from '../../components/Settings/OrgSettingsMenu';
import SettingsContainer from '../../components/Settings/SettingsContainer';
import EventsContainer from '../../components/Settings/events';
import InsightRulesContainer from '../../components/Settings/insightRules';
import PublicAircraft from '../../components/Settings/publicAircraft/PublicAircraft';
import ManageApps from '../../components/Settings/publicAircraft/ManageApps';
import GeofenceContainer from '../../components/Settings/geofence/GeofenceContainer';
import NotificationsContainer from '../../components/Settings/notifications/NotificationsContainer';
import TagContainer from '../../components/Settings/tags/TagContainer';
import UsersContainer from '../../components/Settings/users/UsersContainer';

export const OrganisationSettingsPage = () => {
  const { STL_GO_ORIGIN } = useGlobalConfig();
  const { settingsPage, organisationId } = useParams();
  const goToken = window.localStorage.getItem('hash').replace('Basic ', '');
  const orgSettingsURL = `${STL_GO_ORIGIN}${ORGANISATION}/settings?go_token=${encodeURIComponent(
    goToken
  )}&organisation_id=${organisationId}&hide-sidebar=t#tab=${settingsPage}`;
  const isOldSettingsPage = OLD_SETTINGS_PAGES.includes(settingsPage);

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <OrgSettingsMenu
        activeSettingsPageMenuKey={
          settingsPage.includes('/') ? settingsPage.split('/')[0] : settingsPage
        }
      />
      <div style={{ height: '100%', width: '100%' }}>
        {isOldSettingsPage && (
          <iframe
            id="org-settings-page"
            src={orgSettingsURL}
            title={'Organisation Settings'}
            width="100%"
            height="100%"
            style={{
              border: 0,
              position: 'relative'
            }}
          />
        )}
        {!isOldSettingsPage && (
          <Fragment>
            <Route
              path={`/organisation/:organisationId/settings/notifications-beta`}
              component={NotificationsContainer}
            />
            <Route
              path={`/organisation/:organisationId/settings/spiders`}
              component={SettingsContainer}
            />
            <Route
              path={`/organisation/:organisationId/settings/geofence`}
              component={GeofenceContainer}
            />
            <Route
              path={`/organisation/:organisationId/settings/events`}
              component={EventsContainer}
            />
            <Route
              path={`/organisation/:organisationId/settings/insightRules`}
              component={InsightRulesContainer}
            />
            <Route
              path={`/organisation/:organisationId/settings/publicaircraft`}
              component={PublicAircraft}
            />
            <Route
              path={`/organisation/:organisationId/settings/manageapps`}
              component={ManageApps}
            />
            <Route path={`/organisation/:organisationId/settings/tags`} component={TagContainer} />
            <Route
              path={`/organisation/:organisationId/settings/usersandgroups`}
              component={UsersContainer}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default OrganisationSettingsPage;
