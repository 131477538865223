import React from 'react';
import { getActiveContactId, getConversations } from '../../redux/selectors/spiderTxt';
import { setActiveContactId } from '../../redux/slice/spidertxt/spiderTxt';
import { Contact, ContactType, UIMessage } from '../../types/spidertxt';
import { Avatar, Conversation } from '@chatscope/chat-ui-kit-react';
import { useDispatch, useSelector } from 'react-redux';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { ContactAvatar } from './ContactAvatar';
import { markMessagesAsRead } from '../../redux/slice/spidertxt/thunk';

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

interface ContactListItemProps {
  as: string | typeof Conversation;
  contact: Contact;
}

const ContactListItem: React.FC<ContactListItemProps> = ({ contact }) => {
  const dispatch = useDispatch();
  const activeConversationId = useSelector(getActiveContactId);
  /* Get only the required conversation, otherwise probaby re-render on every conversation update */
  const allConversations = useSelector(getConversations);
  const conversation = allConversations.find(conversation => conversation.contactId === contact.id);

  const { id: contactId, displayName } = contact;
  const { messages, unreadMessageCount } = conversation
    ? conversation
    : { messages: [], unreadMessageCount: 0 };

  let lastMessage: UIMessage | undefined;
  if (messages.length > 0) {
    const sortedMessages = [...messages].sort((a, b) => a.timestamp - b.timestamp);
    lastMessage = sortedMessages[sortedMessages.length - 1];
  }

  return (
    <Conversation
      info={lastMessage ? lastMessage.body : undefined}
      lastSenderName={
        contact.contactType == ContactType.GROUP
          ? lastMessage
            ? lastMessage.senderDisplayName
            : undefined
          : undefined
      }
      name={displayName}
      unreadCnt={unreadMessageCount}
      lastActivityTime={lastMessage ? timeAgo.format(lastMessage.timestamp, 'round') : undefined}
      onClick={() => {
        console.log(`Dispatching ${contactId}`);
        dispatch(setActiveContactId(contactId));
        dispatch(markMessagesAsRead(contactId));
      }}
      active={activeConversationId === contactId}
      key={contactId}
    >
      <ContactAvatar contact={contact} as={Avatar} />
    </Conversation>
  );
};

export default ContactListItem;
