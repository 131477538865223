import React from 'react';

export const BlankConversation = () => {
  return (
    <div className="cs-blank-conversation">
      <h1>SpiderTxt</h1>
      <p>Select a contact to start chatting with them</p>
    </div>
  );
};
