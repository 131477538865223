import {
  Conversation,
  Message,
  MessageStatus,
  MessageToSend,
  MessageType
} from '../../../../../../types/spidertxt';
import { ServiceAbstract } from '../../../ServiceAbstract';
import { Amplify } from '@spidertracks/common';

export enum MessageDeliveryStatusFromAPI {
  SENDING = 'SENDING',
  DELIVERED = 'DELIVERED'
}
export interface MessageFromAPI {
  id: string;
  senderId?: string;
  recipientId: string;
  message: string;
  status: {
    recipientId: string;
    status: MessageDeliveryStatusFromAPI;
  }[];
  createdAt: number;
}

export interface ConversationFromAPI {
  contactId: string;
  totalMessageCount: number;
  unreadMessageCount: number;
  latestMessages: MessageFromAPI[];
}

export class ConversationService extends ServiceAbstract {
  private getFinalMessageDeliveryStatus(statuses: MessageDeliveryStatusFromAPI[]): MessageStatus {
    if (statuses.some(status => status != MessageDeliveryStatusFromAPI.DELIVERED)) {
      return MessageStatus.SENDING;
    }
    return MessageStatus.DELIVERED;
  }

  private transformToDomainMessage(message: MessageFromAPI, currentUserId: string): Message {
    return {
      id: message.id,
      body: message.message,
      senderId: message.senderId,
      contactId: message.recipientId,
      timestamp: message.createdAt,
      type:
        message.senderId === undefined
          ? MessageType.SYSTEM
          : message.senderId == currentUserId
          ? MessageType.SENT
          : MessageType.RECEIVED,
      status: this.getFinalMessageDeliveryStatus(message.status.map(status => status.status))
    };
  }

  private transformToDomainConversation(
    conversation: ConversationFromAPI,
    currentUserId: string
  ): Conversation {
    return {
      contactId: conversation.contactId,
      messages: conversation.latestMessages.map(message =>
        this.transformToDomainMessage(message, currentUserId)
      ),
      unreadMessageCount: conversation.unreadMessageCount
    };
  }

  public async getAllActiveConversations(currentUserId: string): Promise<Conversation[]> {
    try {
      const { conversations } = await Amplify.API.get(
        'api.spidertracks.io-authenticated',
        `v2/v2/conversations`
      );
      return conversations.map((convo: ConversationFromAPI) =>
        this.transformToDomainConversation(convo, currentUserId)
      );
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  /* GET  /v2/conversations/{contactId}/messages */
  public async getMoreMessagesForContact(
    currentUserId: string,
    contactId: string,
    earliestMessageId: string | undefined = undefined,
    limit: number = 20
  ): Promise<Message[]> {
    try {
      const { items: messages } = await Amplify.API.get(
        'api.spidertracks.io-authenticated',
        `v2/v2/conversations/${contactId}/messages`,
        earliestMessageId
          ? {
              queryStringParameters: {
                cursor: earliestMessageId,
                limit
              }
            }
          : {
              queryStringParameters: {
                limit
              }
            }
      );
      return messages.map((message: MessageFromAPI) =>
        this.transformToDomainMessage(message, currentUserId)
      );
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  /*POST  /v2/conversations/{contactId}/messages */
  public async sendMessage(message: MessageToSend): Promise<void> {
    await Amplify.API.post(
      'api.spidertracks.io-authenticated',
      `v2/v2/conversations/${message.contactId}/messages`,
      {
        body: {
          message: message.body
        }
      }
    );
  }

  /* PUT /v2/conversations/{contactId}/messages/{messageId} */
  public async acknowledgeMessage(contactId: string, messageId: string): Promise<void> {
    await this.delay(1000);
  }

  private delay = (millis: number) =>
    new Promise((resolve, reject) => {
      setTimeout(() => resolve(''), millis);
    });
}
