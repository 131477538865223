import React, { useState, useEffect } from 'react';
import { MessageList, MessageListProps } from '@chatscope/chat-ui-kit-react';
import { getContacts, getActiveContactId, getConversations } from '../../redux/selectors/spiderTxt';
import { useDispatch, useSelector } from 'react-redux';
import { MessagePosition } from './Message';
import Message from './Message';
import DateDivider from './DateDivider';
import { ContactType, MessageType, MessageStatus } from '../../types/spidertxt';
import { getMoreMessagesForConversation } from '../../redux/slice/spidertxt/thunk';
import SystemMessage from './systemMessage';
declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    as?: string | typeof MessageList;
  }
}

function isSameDay(d1: Date, d2: Date): boolean {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}
interface ConversationViewProps extends MessageListProps {
  as?: string | typeof MessageList;
}

const ConversationView: React.FC<ConversationViewProps> = () => {
  const dispatch = useDispatch();
  const activeConversationId = useSelector(getActiveContactId);
  const contacts = useSelector(getContacts);
  const conversations = useSelector(getConversations);

  const activeContact = contacts.find(contact => contact.id === activeConversationId);
  const activeContactConversation = conversations.find(
    conversation => conversation.contactId === activeConversationId
  );

  if (activeContact === undefined || activeContactConversation === undefined) {
    return <></>;
  }

  const { messages } = activeContactConversation;

  const sortedMessages = [...messages].sort((a, b) => a.timestamp - b.timestamp);
  if (sortedMessages.length === 0) {
    sortedMessages.push({
      id: 'system',
      senderId: undefined,
      timestamp: new Date().getTime(),
      type: MessageType.SYSTEM,
      status: MessageStatus.DELIVERED,
      senderDisplayName: 'System',
      body: `This is the beginning of the conversation between you and ${
        activeContact.contactType === ContactType.GROUP ? 'the messaging group ' : ''
      }${activeContact.displayName}`,
      contactId: activeContact.id
    });
  }
  const loadMoreMessages = () => {
    if (!isLoadingMoreMessages) {
      setLoadingState(true);
      dispatch(getMoreMessagesForConversation(activeContact.id, setLoadingState));
    }
  };

  useEffect(() => {
    loadMoreMessages();
  }, [activeConversationId]);

  const [isLoadingMoreMessages, setLoadingState] = useState(false);
  return (
    <div className={'cs-conversation-view'}>
      <MessageList
        loading={false} // set when loading messages intially
        loadingMore={isLoadingMoreMessages} // set to true when loading more messages
        onYReachStart={() => {
          loadMoreMessages();
        }}
      >
        {sortedMessages.map((message, index, messageList) => {
          const previousMessage = index > 0 ? messageList[index - 1] : undefined;
          const nextMessage = index < messageList.length - 1 ? messageList[index + 1] : undefined;
          const isLastMessage =
            nextMessage === undefined || nextMessage.senderId !== message.senderId;
          const isFirstMessage =
            previousMessage === undefined || previousMessage.senderId !== message.senderId;
          const isSingleMessage = isFirstMessage && isLastMessage;
          const hasDateChanged =
            previousMessage !== undefined &&
            !isSameDay(new Date(message.timestamp), new Date(previousMessage.timestamp));
          const isFirstMessageInConversation = previousMessage === undefined;
          const isLastMessageInConversation = nextMessage === undefined;
          const position = isSingleMessage
            ? MessagePosition.SINGLE
            : isFirstMessage
            ? MessagePosition.FIRST
            : isLastMessage
            ? hasDateChanged
              ? MessagePosition.SINGLE
              : MessagePosition.LAST
            : hasDateChanged
            ? MessagePosition.FIRST
            : MessagePosition.NORMAL;
          const showStatus = isLastMessageInConversation && message.type == MessageType.SENT;
          const showSenderName =
            activeContact.contactType == ContactType.GROUP &&
            message.type == MessageType.RECEIVED &&
            (position === MessagePosition.FIRST || position === MessagePosition.SINGLE);
          const isSystemMessage = message.type == MessageType.SYSTEM;
          return (
            <div as="MessageListContent2" key={message.id}>
              {isFirstMessageInConversation && (
                <DateDivider timestamp={message.timestamp - 24 * 60 * 60 * 1000} />
              )}
              {hasDateChanged && <DateDivider timestamp={previousMessage.timestamp} />}
              {isSystemMessage ? (
                <SystemMessage message={message} />
              ) : (
                <Message
                  message={message}
                  position={position}
                  showStatus={showStatus}
                  showSenderName={showSenderName}
                />
              )}
            </div>
          );
        })}
      </MessageList>
    </div>
  );
};

export default ConversationView;
