import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import PropTypes from 'prop-types';
import { getCheckedIcon } from '../icons';
import { Table, Tooltip } from 'antd';
import {
  OrganisationGroup,
  OrganisationGroupMember
} from '../../../../common/api/spidertracks-sdk/private/services/UserService';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';

interface ComponentProps {
  groups?: OrganisationGroup[];
  loading: boolean;
  isAircraftVisibilityGroupsAdminEnabled: boolean;
  isNotificationsGroupsAdminEnabled: boolean;
  onRow: (groupId: string) => void;
}

interface TableRow {
  key: React.Key;
  groupName: string;
  notificationsAvailable: boolean;
  spiderTxtAvailable: boolean; // Group visibility for spidertxt, aka show group as a contact in spidertxt
  active: boolean;
  members: OrganisationGroupMember[];
}

const getActiveColumnContent = (active: boolean) => {
  return active ? 'Active' : 'Inactive';
};

const getSpidertxtColumnTitle = () => {
  return (
    <Tooltip
      title={
        <span>
          Enable Spidertxt under <b>Organisation &gt; Spidertxt</b>
        </span>
      }
      placement="bottom"
      overlayStyle={{ backgroundColor: '#fff' }}
    >
      MESSAGING
      <ExclamationCircleFilled className="ml-2" style={{ color: 'rgba(0,0,0,0.25)' }} />
    </Tooltip>
  );
};

export const OrganisationGroupsContainer: React.FC<ComponentProps> = ({
  groups,
  loading,
  isAircraftVisibilityGroupsAdminEnabled,
  isNotificationsGroupsAdminEnabled,
  onRow
}) => {
  const onRowHandler = (record: TableRow) => {
    return {
      onClick: () => onRow(record.key.toString())
    };
  };

  const aircraftVisibilityColumn = {
    title: 'AIRCRAFT VISIBILITY',
    dataIndex: 'aircraftVisibility',
    key: 'aircraftVisibility',
    width: '20%'
  };
  const notificationsColumn = {
    title: 'NOTIFICATIONS',
    dataIndex: 'notificationsAvailable',
    key: 'notificationsAvailable',
    render: (notificationEnabled: boolean) => {
      return getCheckedIcon(notificationEnabled);
    },
    width: '10%'
  };

  const columns: ColumnProps<TableRow>[] = [
    {
      title: 'GROUP NAME',
      dataIndex: 'groupName',
      key: 'groupName',
      defaultSortOrder: 'ascend',
      sorter: (a, b) =>
        a.groupName
          .toLowerCase()
          .localeCompare(b.groupName.toLowerCase(), undefined, { sensitivity: 'base' }),
      width: '20%'
    },
    {
      title: 'MEMBERS',
      dataIndex: 'members',
      key: 'members',
      render: (members: OrganisationGroupMember[]) => {
        return members.length;
      },
      width: '10%'
    },
    {
      title: () => getSpidertxtColumnTitle(),
      dataIndex: 'spiderTxtAvailable',
      key: 'spiderTxtAvailable',
      render: (messagingEnabled: boolean) => {
        return getCheckedIcon(messagingEnabled);
      },
      width: '10%'
    },
    {
      title: 'STATUS',
      dataIndex: 'active',
      key: 'active',
      render: (active: boolean) => {
        return getActiveColumnContent(active);
      },
      width: '10%'
    }
  ];

  if (isNotificationsGroupsAdminEnabled) {
    columns.splice(2, 0, notificationsColumn);
  }
  if (isAircraftVisibilityGroupsAdminEnabled) {
    columns.splice(2, 0, aircraftVisibilityColumn);
  }

  const groupInfoToTableRow = (group: OrganisationGroup): TableRow => ({
    key: group.id,
    groupName: group.name,
    notificationsAvailable: group.notificationsAvailable,
    spiderTxtAvailable: group.textingAvailable,
    active: group.active,
    members: group.members ?? []
  });
  const tableRows = groups?.map(groupInfoToTableRow);

  return (
    <div>
      <Table
        className="groups-container"
        data-testid={'groups-table'}
        pagination={{ pageSize: 10, hideOnSinglePage: true }}
        rowKey="key"
        bordered
        dataSource={tableRows}
        columns={columns}
        loading={loading}
        onRow={onRowHandler}
      />
    </div>
  );
};

OrganisationGroupsContainer.propTypes = {
  groups: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  isAircraftVisibilityGroupsAdminEnabled: PropTypes.bool.isRequired,
  isNotificationsGroupsAdminEnabled: PropTypes.bool.isRequired,
  onRow: PropTypes.func.isRequired
};
