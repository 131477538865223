import React from 'react';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';

interface ComponentProps {
  groupId: string;
  setIsSaving: (isSaving: boolean) => void;
  removeOrganisationGroup: (groupId: string) => void;
}

const getContent = () => (
  <>
    <p>Are you sure you want to remove the selected group?</p>
    <p>This change will affect all users in this group.</p>
  </>
);

export const showRemoveOrganisationGroupModal = ({
  groupId,
  setIsSaving,
  removeOrganisationGroup
}: ComponentProps) =>
  Modal.confirm({
    title: 'Remove Group',
    icon: <ExclamationCircleFilled />,
    content: getContent(),
    okText: 'Remove Group',
    okButtonProps: { type: 'danger' },
    onOk() {
      setIsSaving(true);
      removeOrganisationGroup(groupId);
    }
  });
