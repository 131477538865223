import React from 'react';
import { MessageSeparator } from '@chatscope/chat-ui-kit-react';
interface DateDividerProps {
  timestamp: number;
}

const DateDivider: React.FC<DateDividerProps> = ({ timestamp }) => {
  const date = new Date(timestamp).toLocaleDateString();
  return <MessageSeparator>{date}</MessageSeparator>;
};

export default DateDivider;
