import { Message, MessageStatus, MessageType } from '../types/spidertxt';

export type MessageFromSocket = {
  id: string;
  recipientId: string;
  isRead: boolean;
  senderId: string;
  messageBody: string;
  createdDate: number;
  displayDate: number;
  status: string;
  type: string;
  date: string;
  time: string;
};

export const transformToDomainMessage = (message: MessageFromSocket): Message => {
  return {
    id: message.id,
    body: message.messageBody,
    senderId: message.senderId,
    contactId: message.senderId,
    timestamp: message.createdDate,
    // This can also be of System message type, but right now we have no way of identifying a system message
    type: MessageType.RECEIVED,
    status: MessageStatus.SENDING
  };
};
