import React from 'react';
import {
  Avatar,
  ConversationHeader as CSConversationHeader,
  ConversationHeaderProps
} from '@chatscope/chat-ui-kit-react';
import { getContacts, getActiveContactId } from '../../redux/selectors/spiderTxt';
import { useSelector } from 'react-redux';
import { ContactAvatar } from './ContactAvatar';
import ConversationHeaderCollapsible from './conversationHeaderCollapsible';

interface ExtentedConversationHeader extends ConversationHeaderProps {
  as?: string | typeof CSConversationHeader;
}

const ConversationHeader: React.FC<ExtentedConversationHeader> = () => {
  const activeConversationId = useSelector(getActiveContactId);
  const contacts = useSelector(getContacts);
  const activeContact = contacts.find(contact => contact.id === activeConversationId);
  if (activeContact === undefined) {
    return <></>;
  }
  return (
    <CSConversationHeader>
      <ContactAvatar as={Avatar} contact={activeContact} />
      <CSConversationHeader.Content userName={activeContact.displayName}>
        <ConversationHeaderCollapsible key={activeContact.id} />
      </CSConversationHeader.Content>
    </CSConversationHeader>
  );
};

export default ConversationHeader;
